import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { ToastService } from 'services/toast-service';
import { MaterialConstituent } from 'models';
import { ValidationController } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { MaterialBase } from './material-base';

@autoinject
export class MaterialDetail extends MaterialBase {
  protected isSaving = false;
  private materialsToDelete: MaterialConstituent[] = [];

  constructor(
    materialService: MaterialService,
    errorService: ErrorService,
    router: Router,
    toastService: ToastService,
    eventAggregator: EventAggregator,
    validationController: ValidationController,
    t: I18N,
    private deleteDialogService: DeleteDialogService
  ) {
    super(materialService, router, toastService, errorService, eventAggregator, validationController, t);
  }

  async activate(params: { Id: number }) {
    await this.getMaterialById(params.Id);
  }

  protected async getMaterialById(id: number) {
    try {
      const query = new URLSearchParams();
      query.set('$expand', 'MaterialConstituents');
      this.material = await this.materialService.get(`${id}?${query.toString()}`);
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  protected async updateMaterial() {
    try {
      if (!(await this.isValid())) {
        return;
      }
      this.isSaving = true;

      const removalJobs = this.materialsToDelete.map((x) =>
        this.materialService.removeMaterialConstituent(this.material.Id, x.Id)
      );
      await Promise.all(removalJobs);

      await this.materialService.put(this.material, this.material.Id);

      this.eventAggregator.publish('materialListReset', 1);
      this.toastService.showSuccess('material.updated');
      this.router.navigateToRoute('material-list');
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isSaving = false;
  }

  protected async deleteMaterial() {
    this.deleteDialogService.confirmBeforeDelete(async () => {
      try {
        await this.materialService.delete(this.material.Id);
        this.eventAggregator.publish('materialListReset', 1);
        this.toastService.showSuccess('material.deleted');
        this.router.navigateToRoute('material-list');
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }

  protected async removeMaterialConstituent(index: any) {
    this.materialsToDelete.push(this.material.MaterialConstituents.splice(index, 1)[0]);
  }

  protected gotoParentView() {
    this.eventAggregator.publish('materialListReset', 1);
    this.router.navigateToRoute('material-list');
  }
}

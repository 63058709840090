import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController } from 'aurelia-validation';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { ToastService } from 'services/toast-service';
import { MaterialBase } from './material-base';

@autoinject
export class MaterialNew extends MaterialBase {
  protected isSaving = false;

  constructor(
    materialService: MaterialService,
    router: Router,
    toastService: ToastService,
    errorService: ErrorService,
    eventAggregator: EventAggregator,
    validationController: ValidationController,
    t: I18N
  ) {
    super(materialService, router, toastService, errorService, eventAggregator, validationController, t);
  }

  protected onRemoveConstituent(index: number) {
    this.material.MaterialConstituents.splice(index, 1);
  }

  protected async createMaterial() {
    try {
      if (!(await this.isValid())) {
        return;
      }
      this.isSaving = true;
      const result = await this.materialService.post(this.material);
      this.eventAggregator.publish('materialListReset', 1);
      this.toastService.showSuccess('material.created');
      this.router.navigateToRoute('material-detail', { Id: result.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.isSaving = false;
  }
}

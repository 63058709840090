import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MaterialAmountType, MaterialConstituent, ProductMaterialType } from 'models';
import { ValidationController } from 'aurelia-validation';
import { ProductMaterialTypeService } from 'services';

@autoinject
export class MaterialForm {
  @bindable()
  validationController: ValidationController;

  @bindable()
  material: Models.Material;

  @bindable()
  onRemoveConstituent: (index: number, constituent: MaterialConstituent) => void;

  private allMaterials: ProductMaterialType[] = [];
  protected get materials() {
    return JSON.parse(JSON.stringify(this.allMaterials));
  }

  constructor(
    private errorService: ErrorService,
    private productMaterialsService: ProductMaterialTypeService
  ) {}

  protected async attached() {
    await this.getAllMaterials();
  }

  protected async getAllMaterials() {
    try {
      this.allMaterials = await this.productMaterialsService.getAll('?$expand=ProductMaterialCategory');
    } catch (err) {
      this.errorService.handleError(err);
    }
  }

  protected addMaterial() {
    if (!this.material.MaterialConstituents) this.material.MaterialConstituents = [];
    const amountInProduct =
      this.material.MaterialConstituents?.reduce?.((acc, x) => acc + (+x.MaterialAmountInProduct || 0), 0) || 0;

    this.material.MaterialConstituents.push({
      Id: undefined,
      MaterialId: undefined,
      MaterialTypeId: undefined,
      MaterialAmountType: MaterialAmountType.Weight,
      MaterialAmountInProduct: Math.max(100 - (+amountInProduct || 0), 0),
    });
  }

  protected setMaterialId(index: number, materialTypeId: number) {
    this.material.MaterialConstituents[index].MaterialTypeId = +materialTypeId;
  }
}

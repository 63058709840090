import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MaterialService } from 'services/material-service';
import { ToastService } from 'services/toast-service';

export class MaterialBase {
  protected material: Models.Material = new Models.Material();

  constituentRules(index: number, o: object) {
    return ValidationRules.ensure(`MaterialTypeId_${index}`)
      .required()
      .withMessage(this.t.tr('validation.required'))
      .ensure(`MaterialAmountInProduct_${index}`)
      .required()
      .withMessage(this.t.tr('validation.required'))
      .min(1)
      .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 1 }))
      .max(100)
      .withMessage(this.t.tr('validation.mustBeLessOrEqualToValue', { value: 100 }))
      .on(o);
  }

  constructor(
    protected materialService: MaterialService,
    protected router: Router,
    protected toastService: ToastService,
    protected errorService: ErrorService,
    protected eventAggregator: EventAggregator,
    protected validationController: ValidationController,
    protected t: I18N
  ) {}

  protected async isValid() {
    this.validationController.reset();

    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('validation.required'))
      .maxLength(100)
      .withMessage(this.t.tr('validation.lengthLessOrEqualToValue', { value: 100 }))
      .on(this.material);

    if (this.material?.MaterialConstituents?.length) {
      let i = 0;
      for (const materialConstituent of this.material.MaterialConstituents) {
        const copy = JSON.parse(JSON.stringify(materialConstituent));
        Object.keys(copy).forEach((key) => {
          const newKey = `${key}_${i}`;
          copy[newKey] = copy[key];
          delete copy[key];
        });

        this.constituentRules(i, copy);
        await this.validationController.validate({
          object: copy,
        });
        i++;
      }
    }

    await this.validationController.validate({
      object: this.material,
    });

    return this.validationController.errors.length === 0;
  }
}
